<template>
    <h1>
        <span v-if="isEdit == 'true'">Edit Event</span>
        <span v-else>Add Event</span>
    </h1>
    <br><hr><br>
    <div class="flex flex-col gap-6">
        <v-row>
            <v-col>
                <label class="font-semibold">Banner</label> <span v-if="errors.banner" style="color: #B91E3A;">{{ errors.banner[0] }}</span>
                <label for="bannerFile">
                    <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                        <input id="bannerFile" type="file" hidden @change="handleBannerFile">
                        <img v-if="bannerPreview || bannerEdit == true" :src="bannerEdit == true ? data.banner : bannerPreview" alt="Banner Preview" style="max-height: 200px; object-fit: cover;">
                        <span v-else>Choose file (1366 x 768)</span>
                    </div>
                </label>
            </v-col>
            <v-col>
                <label class="font-semibold">Thumbnail</label> <span v-if="errors.thumbnail" style="color: #B91E3A;">{{ errors.thumbnail[0] }}</span>
                <label for="thumbnailFile">
                    <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                        <input id="thumbnailFile" type="file" hidden @change="handleThumbnailFile">
                        <img v-if="thumbnailPreview || thumbnailEdit == true" :src="thumbnailEdit == true ? data.thumbnail : thumbnailPreview" alt="Thumbnail Preview" style="max-height: 200px; object-fit: cover;">
                        <span v-else>Choose file (847 x 574)</span>
                    </div>
                </label>
            </v-col>
        </v-row>
        <div class="flex items-center gap-6 w-full">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Title
                </span>
                <input 
                    v-model="data.title"
                    @input="validateInput"
                    type="text" 
                    name="title"
                    maxlength="100"
                    class="rounded-md border p-2"
                    placeholder="Event title"
                >
            </div>
            <div class="relative flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Registration Link
                </span>
                <input 
                    v-model="data.link"
                    type="text" 
                    name="link"
                    class="rounded-md border p-2"
                    placeholder="Registration Link"
                >
                <span
                    v-if="errors['link']" 
                    class="absolute -bottom-4 text-smicos-secondary text-xs font-semibold"
                >
                    {{ errors['link'] }}
                </span>
            </div>
        </div>
        <div class="flex items-center gap-6 w-full">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Event Start
                </span>
                <input 
                    v-model="data.event_start"
                    type="datetime-local" 
                    name="event_start"
                    class="rounded-md border p-2"
                >
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Event End
                </span>
                <input 
                    v-model="data.event_end"
                    type="datetime-local" 
                    name="event_event"
                    class="rounded-md border p-2"
                >
            </div>
        </div>
        <div class="flex items-center gap-6 w-full">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Description
                </span>
                <textarea
                    v-model="data.description"
                    class="resize-none border rounded-lg p-2"
                    rows="8"
                />
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Key Topics
                </span>
                <textarea
                    v-model="data.key_topics"
                    class="resize-none border rounded-lg p-2"
                    rows="8"
                />
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Event Summary
                </span>
                <textarea
                    v-model="data.event_summary"
                    class="resize-none border rounded-lg p-2"
                    rows="8"
                />
            </div>
        </div>

        <!-- SEO -->
        <h1 class="font-semibold border-b-[2px] border-black pb-4"> 
            SEO
        </h1>
        <div class="flex items-center gap-4">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Meta Title
                </span>
                <input 
                    v-model="data.meta_title"
                    type="text" 
                    name="event_start"
                    class="rounded-md border p-2"
                    placeholder="Meta Title"
                >
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Meta Description
                </span>
                <input 
                    v-model="data.meta_description"
                    type="text" 
                    name="event_event"
                    class="rounded-md border p-2"
                    placeholder="Meta Description"
                >
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Meta Keywords
                </span>
                <input 
                    v-model="data.meta_keywords"
                    type="text" 
                    name="event_start"
                    class="rounded-md border p-2"
                    placeholder="Meta Keywords"
                >
            </div>
        </div>
        <div class="flex items-center justify-end gap-6">
            <button 
                v-if="isEdit == 'true'"
                @click.prevent="updateEvent()" 
                class="bg-[#00AEEE] text-white rounded-md px-6 py-2"
            >
                Update
            </button>
            <button 
                v-else
                @click.prevent="saveEvent()"
                class="bg-[#00AEEE] text-white rounded-md px-6 py-2"
            >
                Save
            </button>
            <button 
                @click="routeTo('/cms/events')"
                class="bg-gray-400 text-white rounded-md px-6 py-2"
            >   
                Cancel
            </button>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import Editor from '@tinymce/tinymce-vue'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const routeTo = (url) => {
   router.push(url);
};
const eventSlug = ref(route.query.eventSlug);
const isEdit = ref(route.query.isEdit);

const bannerFile = ref();
const bannerEdit = ref();
const thumbnailFile = ref();
const thumbnailEdit = ref();

const bannerPreview = ref();
const thumbnailPreview = ref();

const data = ref({
    title: "",
    link: "",
    description: "",
    key_topics: "",
    event_summary: "",
    event_start: "",
    event_end: "",
    thumbnail: thumbnailFile,
    banner: bannerFile,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
});

// utils
const validateInput = (event) => {
    const pattern = /^[a-zA-Z0-9.,!?:;\-()`" ]*$/;
    if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value
            .replace(/[^a-zA-Z0-9.,!?:;\-()`" ]/g, "")
            .replace(/[\p{Emoji_Presentation}\p{Emoji}\u200d\uFE0F]/gu, "");
        data.value.title = event.target.value;
    }
}

const errors = ref([]);

const fetchEditData = () => {
    window.axios.post(`/cms/events/${eventSlug.value}/edit`)
    .then(response => {
        data.value.title = response.data.title;
        data.value.link = response.data.registration_link;
        data.value.description = response.data.description;
        data.value.key_topics = response.data.key_topics;
        data.value.event_summary = response.data.event_summary;
        data.value.event_start = response.data.start_at;
        data.value.event_end = response.data.end_at;
        data.value.meta_title = response.data.meta_title;
        data.value.meta_description = response.data.meta_description;
        data.value.meta_keywords = response.data.meta_keywords;

        data.value.banner = response.data.banner;
        data.value.thumbnail = response.data.thumbnail;

        bannerEdit.value = true;
        thumbnailEdit.value = true;
    })
    .catch(error => {
        console.error(error);
    });
}

const saveEvent = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (!data.value.link.match(urlPattern)) {
        errors.value['link'] = "Invalid URL format";
    }

    axios.post('/cms/events/store', data.value,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        alert(response.data);
        data.value = {};
        router.push('/cms/events');
    })
    .catch(error => {
        console.error(error);
    });
}

const updateEvent = () => {
    axios.post(`/cms/events/${eventSlug.value}/update`, data.value,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        alert(response.data);
        data.value = {};
        router.push('/cms/events');
    })
    .catch(error => {
        errors.value = error.response.data.errors;

        setTimeout(() => {
            errors.value = [];
        }, 5000);
    });
}

const handleBannerFile = (event) => {
  const file = event.target.files[0];

  if (file) {
    bannerFile.value = new File([file], file.name, { type: file.type });

    // Use FileReader to display image preview
    const reader = new FileReader();
    reader.onload = (e) => {
        bannerPreview.value = e.target.result; // Set the preview source
    };
    reader.readAsDataURL(file);


    bannerEdit.value = false;
  } else {

  }
};

const handleThumbnailFile = (event) => {
  const file = event.target.files[0];

  if (file) {
    thumbnailFile.value = new File([file], file.name, { type: file.type });

    // Use FileReader to display image preview
    const reader = new FileReader();
    reader.onload = (e) => {
        thumbnailPreview.value = e.target.result; // Set the preview source
    };
    reader.readAsDataURL(file);

    thumbnailEdit.value = false;
  } else {

  }
};

onMounted(()=>{
    if(isEdit.value == 'true') {
        fetchEditData();
    }
});

</script>
